import { render, staticRenderFns } from "./MRelatedTable.vue?vue&type=template&id=dae8bb90&scoped=true"
import script from "./MRelatedTable.vue?vue&type=script&lang=js"
export * from "./MRelatedTable.vue?vue&type=script&lang=js"
import style0 from "./MRelatedTable.vue?vue&type=style&index=0&id=dae8bb90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae8bb90",
  null
  
)

export default component.exports
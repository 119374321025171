<template>
  <v-submit-dialog
    txt="确认"
    url="system/auth/users/personal"
    :post-data="user"
    @success="$emit('success')"
    @fail="$emit('fail')"
    @close="$emit('close')"
    :dialog="dialog"
    method="post"
    icon="nsfont-personal"
    title="个人信息"
    :max-width="$root.dialogWidth"
    notify="修改成功"
  >
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <image-uploader
            :src="user.headimgurl"
            :reset="$store.state.user.headimgurl"
            label="头像"
            force-fieldset
            :remove="false"
            :rules="rules.headimgurl"
            @success="user.headimgurl = $event"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field v-model="user.nickname" label="姓名" outlined dense :rules="rules.nickname"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="user.deptName" label="部门" disabled outlined dense :rules="rules.nickname"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="user.positionName" disabled label="职位" outlined dense :rules="rules.nickname"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-textarea v-model="user.remark" label="备注" outlined dense :maxlength="255"></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </v-submit-dialog>
</template>

<script>
  import VSubmitDialog from "../extends/VSubmitDialog";
  import ImageUploader from "../ImageUploader";
  import { required } from "@/plugins/rules";

  export default {
    name: "InfoDialog",
    components: {
      VSubmitDialog,
      ImageUploader
    },
    data() {
      return {
        user: {
          nickname: "",
          remark: "",
          headimgurl: ""
        },
        rules: {
          headimgurl: [v => required(v, "请上传头像")],
          nickname: [v => required(v, "请输入姓名")]
        }
      };
    },
    props: {
      dialog: {
        type: Boolean,
        required: true
      }
    },

    watch: {
      "$store.state.user": {
        handler() {
          this.initUser();
        },
        deep: true
      }
    },
    created() {
      this.initUser();
    },
    methods: {
      initUser() {
        let user = this.$store.state.user;
        for (let prop in this.user) {
          this.user[prop] = user[prop] || "";
        }
        this.user.deptName = user.dept?.name;
        if (user.authPositionList) {
          this.user.positionName = user.authPositionList.map(o => o.name).join(", ");
        }
      }
    }
  };
</script>

<style scoped></style>

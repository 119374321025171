import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Axios from "./plugins/axios";
import EventProxy from "eventproxy";
import Notification from "@/components/notification";
import Confirm from "@/components/confirm";
import ResizeEvent from "./plugins/ResizeEvent";
import Dict from "./plugins/Dict";
import Cookie from "js-cookie";
import UserPermissionHelper from "./plugins/UserPermissionHelper";
import FluidFormItem from "./components/extends/form/FluidFormItem";
import MTextField from "./components/extends/form/MTextField";
import MTextarea from "./components/extends/form/MTextarea";
import MSelect from "./components/extends/form/MSelect";
import MCheckbox from "./components/extends/form/MCheckbox";
import MRadio from "./components/extends/form/MRadio";
import MComboTree from "./components/extends/form/MComboTree";
import MEditor from "./components/extends/form/MEditor";
import MRelatedTable from "./components/extends/form/MRelatedTable";
import MDatetimePicker from "./components/extends/form/MDatetimrPicker";

import "@/assets/font/iconfont.css";
import "@/assets/style/vueditor.scss";
import "@/assets/style/common.scss";

Vue.config.productionTip = false;
Vue.use(Axios);
Vue.component("FluidFormItem", FluidFormItem);
Vue.component("MTextField", MTextField);
Vue.component("MTextField", MTextField);
Vue.component("MTextarea", MTextarea);
Vue.component("MSelect", MSelect);
Vue.component("MCheckbox", MCheckbox);
Vue.component("MRadio", MRadio);
Vue.component("MComboTree", MComboTree);
Vue.component("MEditor", MEditor);
Vue.component("MRelatedTable", MRelatedTable);
Vue.component("MDatetimePicker", MDatetimePicker);

if (process.env.NODE_ENV === "development") {
  Vue.prototype.$ctx = "/wms/";
} else {
  Vue.prototype.$ctx = "/";
}
Vue.prototype.$publicPath = "https://res.mingweigz.com/wms/";
/**
 * 事件代理组件
 * @type {EventProxy}
 */
Vue.prototype.$ep = new EventProxy();
/**
 * 全局设置消息通知组件，和选择框组件
 */
Vue.prototype.$notify = Notification;
Vue.prototype.$confirm = Confirm;
/**
 * 配置文件
 * @type {{}}
 */
Vue.prototype.$dict = new Dict();
/**
 * 窗口大小改变事件
 * @type {ResizeEvent}
 */
let resizeEvent = new ResizeEvent();
Vue.prototype.$resize = resizeEvent;

window.onbeforeunload = function() {
  let xsrfToken = store.state.XSRFToken;
  if (xsrfToken) {
    Cookie.set("xsrf-token", xsrfToken);
  }
};
/**
 * 加载数据字典和当前用户信息
 */
let dictPromise = new Promise(resolve => {
  fetch(process.env.NODE_ENV === "development" ? "/wms/dictionaries" : "/dictionaries")
    .then(data => {
      return data.json();
    })
    .then(dictList => {
      Vue.prototype.$dict.load(dictList);
      resolve();
    });
});
/**
 * 加载当前用户数据，加载完成后才会初始化Vue
 */
let checkPromise = new Promise(resolve => {
  Vue.prototype.$http.get("login/check").then(logined => {
    if (logined) {
      let xsrfToken = Cookie.get("xsrf-token");
      if (xsrfToken) {
        Cookie.remove("xsrf-token");
      }
      store.commit("setXSRFToken", xsrfToken);
      Vue.prototype.$http.get("auth/passed/me").then(data => {
        UserPermissionHelper.generate(data);
        resolve();
      });
    } else {
      resolve();
    }
  });
});
// 初始化Vue
Promise.all([dictPromise, checkPromise]).then(() => {
  new Vue({
    data() {
      return {
        dialogWidth: 600,
        editDialogWidth: 1200,
        viewDialogWidth: 1200
      };
    },
    created() {
      resizeEvent.on(() => {
        this.editDialogWidth = this.calcWidth();
        this.viewDialogWidth = this.calcWidth();
        this.dialogWidth = this.calcWidth(600);
      });
    },
    methods: {
      calcWidth(max) {
        let width = window.innerWidth * 0.8;
        if (max && width > max) {
          width = max;
        }
        return width;
      }
    },
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
});
